
import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
  name: 'Page404',
  data() {
    return {
      oops: '抱歉!',
      headline: '当前页面不存在...',
      info: '请检查您输入的网址是否正确，或点击下面的按钮返回首页。',
      btn: '返回首页',
    }
  },
})
